import Swiper from 'swiper/bundle';

const swiper = new Swiper(".teaser--swiper", {
    slidesPerView: 1,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".teaser__pagination",
        clickable: true
    },
    breakpoints: {
        992: {
          slidesPerView: 3,
        }
    },
});