class OfferListManager {
    offerList: HTMLFormElement | null;

    constructor() {
        this.offerList = document.querySelector('.offer-list');
        this.initialize();
    }

    private initialize(): void {
        if (this.offerList) {
            this.addEventListeners();
            this.initializeUIComponents();
            this.submitPagination();
            this.resetFilter();
            this.submitOfferForm();
            this.requestInitialSubmit();

        }
    }

    /**
     * Adds all necessary event listeners to the offer list components.
     */
    private addEventListeners(): void {
        const settingsDropdown = this.offerList?.querySelector('.offer-list__settings-dropdown') as HTMLSelectElement | null;
        const settingsButtons = this.offerList?.querySelectorAll('.offer-list__settings-button') as NodeListOf<HTMLButtonElement> | null;
        const rangeInput = this.offerList?.querySelector('.filter__range') as HTMLInputElement | null;
        const rangeOutput = this.offerList?.querySelector('.filter__range-output') as HTMLElement | null;
        const searchSubmitButton = this.offerList?.querySelector('.search__button') as HTMLButtonElement | null;
        const locationSubmitButton = this.offerList?.querySelector('.filter__input-icon') as HTMLButtonElement | null;
        const radioButtons = this.offerList?.querySelectorAll('.filter__radio') as NodeListOf<HTMLInputElement> | null;


        // Settings Dropdown
        if (settingsDropdown) {
            settingsDropdown.addEventListener('input', () => {
                this.submitPagination(true);
                this.offerList?.requestSubmit();
            });
        }

        if (settingsButtons) {
            settingsButtons.forEach((button) => {
                button.addEventListener('click', (e: Event) => this.handleSettingsButtonClick(e, button));
            });
        }

        if (rangeInput && rangeOutput) {
            rangeInput.addEventListener('input', () => {
                rangeOutput.innerHTML = `${rangeInput.value} km`;
            });
        }

        if (searchSubmitButton) {
            searchSubmitButton.addEventListener('click', (e: Event) => {
                e.preventDefault();
                this.offerList?.requestSubmit();
            });
        }

        if (locationSubmitButton) {
            locationSubmitButton.addEventListener('click', () => {
                this.offerList?.requestSubmit();
            });
        }

        if (this.offerList) {
            this.offerList.addEventListener('submit', (e: Event) => this.handleFormSubmit(e));
        }
        if (radioButtons) {
            radioButtons.forEach((radioButton) => {
                radioButton.addEventListener('input', () => this.handleRadioInput(radioButton));
            });
        }
    }


    /**
     * Initializes UI components such as details toggle and checkbox updates.
     */
    private initializeUIComponents(): void {
        this.openDetails();
        this.updateCheckbox();
    }

    /**
     * Handles the form submission with AJAX.
     */
    private async handleFormSubmit(event: Event): Promise<void> {
        event.preventDefault();
        await this.submitOfferForm();
    }

    /**
     * Submits the offer list form via AJAX.
     */
    private async submitOfferForm(): Promise<void> {
        if (!this.offerList) {
            return;
        }

        const loader = this.offerList.querySelector('.loader') as HTMLDivElement | null;
        if (loader) {
            loader.classList.remove('d-none');
        }

        const formData = new FormData(this.offerList);
        const data = new URLSearchParams();

        formData.forEach((value, key) => {
            data.append(key, value as string);
        });

        const loadMoreButton = this.offerList.querySelector('.offer-list__loadMoreBtn') as HTMLButtonElement | null;
        const currentPage = loadMoreButton?.getAttribute('data-page') || '1';
        data.append('tx_smediaoffer_ajaxlist[currentPageNumber]', currentPage);

        try {
            const actionUrl = this.offerList.getAttribute('action') || window.location.href;
            const response = await fetch(actionUrl, {
                method: 'POST',
                body: data,
            });

            if (!response.ok) throw new Error('Network response was not ok');

            const result = await response.text();

            if (loadMoreButton) {
                loader?.classList.add('d-none');

                const resultsWrapper = this.offerList.querySelector('.offer-list__results .offer-list__results-wrapper') as HTMLElement | null;
                if (resultsWrapper) {
                    if (parseInt(loadMoreButton.dataset.page || '1') > 1) {
                        resultsWrapper.innerHTML += result;
                    } else {
                        resultsWrapper.innerHTML = result;
                    }
                }

                const distanceDropdown = this.offerList.querySelector('.offer-list__dropdown') as HTMLSelectElement | null;

                this.openDetails();

                const filterInput = this.offerList.querySelector<HTMLInputElement>('.filter__input');
                if (filterInput?.value.trim() !== '') {
                    distanceDropdown?.classList.remove('offer-list__dropdown--hidden');
                } else {
                    distanceDropdown?.classList.add('offer-list__dropdown--hidden');
                }

                const newCounter = document.querySelector('.newCounter')?.innerHTML;
                const counter = this.offerList.querySelector('.totalResults') as HTMLSpanElement | null;
                if (counter) {
                    counter.innerHTML = newCounter ?? '0';
                }

                if (loadMoreButton && newCounter) {
                    if (parseInt(newCounter) <= (parseInt(loadMoreButton.dataset.page || '1') * 4)) {
                        loadMoreButton.classList.add('d-none');
                    } else {
                        loadMoreButton.classList.remove('d-none');
                    }
                }
            }
        } catch (error) {
            loader?.classList.add('d-none');
        }
    }


    /**
     * Toggles the active state of settings buttons and adjusts the results view.
     * @param event The click event.
     * @param button The settings button that was clicked.
     */
    private handleSettingsButtonClick(event: Event, button: HTMLButtonElement): void {
        event.preventDefault();

        const resultWrapper = this.offerList?.querySelector('.offer-list__results .offer-list__results-wrapper') as HTMLElement | null;
        const activeButton = this.offerList?.querySelector('.offer-list__settings-button--active') as HTMLButtonElement | null;

        if (activeButton) {
            activeButton.classList.remove('offer-list__settings-button--active');
        }

        button.classList.add('offer-list__settings-button--active');

        if (resultWrapper) {
            resultWrapper.classList.toggle('offer-list__results-wrapper--tiles');
        }
    }

    /**
     * Initializes the details toggle functionality.
     */
    private openDetails(): void {
        const offerItems = this.offerList?.querySelectorAll('.offer-list__item') as NodeListOf<HTMLElement> | null;

        if (offerItems) {
            offerItems.forEach((item) => {
                const toggleButton = item.querySelector('.offer-list__button') as HTMLButtonElement | null;
                const additionalContent = item.querySelector('.offer-list__additional') as HTMLElement | null;

                if (toggleButton && additionalContent) {
                    toggleButton.addEventListener('click', (e: Event) => this.handleDetailToggle(e, toggleButton, additionalContent, item));
                }
            });
        }
    }

    /**
     * Handles the toggle of additional details for an offer item.
     * @param event The click event.
     * @param button The button that was clicked.
     * @param additionalContent The additional content to toggle.
     * @param item The offer list item element.
     */
    private handleDetailToggle(event: Event, button: HTMLButtonElement, additionalContent: HTMLElement, item: HTMLElement): void {
        event.preventDefault();

        button.classList.toggle('offer-list__button--active');
        additionalContent.classList.toggle('offer-list__additional--active');
        item.classList.toggle('offer-list__item--active');

        button.textContent = button.classList.contains('offer-list__button--active') ? 'Weniger anzeigen' : 'Mehr erfahren';
    }

    /**
     * Updates the checkbox filters and counters.
     */
    private updateCheckbox(): void {
        if (!this.offerList) return;

        const filterItems = Array.from(this.offerList.getElementsByClassName('filter__item') as HTMLCollectionOf<HTMLElement>);

        filterItems.forEach(filterItem => {
            if (!filterItem.classList.contains('filter__item--open')) {
                const filterTitle = filterItem.querySelector('.filter__title') as HTMLElement | null;

                if (filterTitle) {
                    filterTitle.addEventListener('click', () => this.handleFilterTitleClick(filterItem));
                }
            }

            const allCheckboxes = Array.from(filterItem.getElementsByClassName('filter__checkbox') as HTMLCollectionOf<HTMLInputElement>);
            let counter: number = 0;

            allCheckboxes.forEach(checkbox => {
                if (checkbox.checked) {
                    counter++;
                    this.updateCounter(filterItem, counter);
                }

                checkbox.addEventListener('input', () => this.handleCheckboxInput(filterItem, allCheckboxes, counter));
            });
        });
    }

    private handleRadioInput(radioButton: HTMLInputElement): void {
        this.submitPagination(true);
        this.offerList?.requestSubmit();
    }

    /**
     * Handles the click event on filter titles.
     * @param filterItem The filter item element.
     */
    private handleFilterTitleClick(filterItem: HTMLElement): void {
        if (filterItem.classList.contains('filter__item--toggle')) {
            const filterItems = Array.from(this.offerList?.getElementsByClassName('filter__item') as HTMLCollectionOf<HTMLElement>);

            filterItems.forEach((item, index) => {
                if (index > 0) {
                    if (item.classList.contains('filter__item--mobile')) {
                        item.classList.remove('filter__item--mobile');
                    } else {
                        item.classList.add('filter__item--mobile');
                    }
                }
            });
        } else {
            if (filterItem.classList.contains('filter__item--active')) {
                filterItem.classList.remove('filter__item--active');
            } else {
                filterItem.classList.add('filter__item--active');
            }
        }
    }

    /**
     * Handles the input event on checkboxes.
     * @param filterItem The filter item element.
     * @param allCheckboxes The list of checkboxes within the filter item.
     * @param counter The current count of checked checkboxes.
     */
    private handleCheckboxInput(filterItem: HTMLElement, allCheckboxes: HTMLInputElement[], counter: number): void {
        let updatedCounter = 0;
        allCheckboxes.forEach(checkbox => {
            if (checkbox.checked) {
                updatedCounter++;
            }
        });

        this.updateCounter(filterItem, updatedCounter);
        this.submitPagination(true);
        this.offerList?.requestSubmit();
    }

    /**
     * Submits pagination by setting up the load more button.
     * @param reset Whether to reset the pagination to the first page.
     */
    private submitPagination(reset: boolean = false): void {
        const loadMoreButton = this.offerList?.querySelector('.offer-list__loadMoreBtn') as HTMLButtonElement | null;

        if (loadMoreButton) {
            let page: number = parseInt(loadMoreButton.dataset.page || '1');

            if (reset) {
                loadMoreButton.dataset.page = '1';
            }

            loadMoreButton.onclick = (e: Event) => {
                e.preventDefault();
                page++;
                loadMoreButton.dataset.page = String(page);
                this.offerList?.requestSubmit();
            };
        }
    }

    /**
     * Resets all filters to their default states.
     */
    private resetFilter(): void {
        if (!this.offerList) return;

        const rangeOutput = this.offerList.querySelector('.filter__range-output') as HTMLElement | null;
        const resetButton = this.offerList.querySelector('.filter__reset') as HTMLButtonElement | null;

        if (resetButton && rangeOutput) {
            resetButton.addEventListener('click', (e: Event) => this.handleResetFilter(e, rangeOutput));
        }
    }

    /**
     * Handles the reset filter button click event.
     * @param event The click event.
     * @param rangeOutput The range output element.
     */
    private handleResetFilter(event: Event, rangeOutput: HTMLElement): void {
        event.preventDefault();

        const rangeInput = this.offerList?.querySelector('.filter__range') as HTMLInputElement | null;
        const filterItems = Array.from(this.offerList?.getElementsByClassName('filter__item') as HTMLCollectionOf<HTMLElement>);

        filterItems.forEach(filterItem => {

            const allCheckboxes = Array.from(filterItem.getElementsByClassName('filter__checkbox') as HTMLCollectionOf<HTMLInputElement>)

            allCheckboxes?.forEach(checkbox => {
                checkbox.removeAttribute('checked');
            })

            this.updateCounter(filterItem, 0);
        });


        if (rangeInput && rangeOutput) {
            rangeInput.value = '10';
            rangeOutput.innerHTML = '10 km';
        }

        this.submitPagination(true);
        this.offerList?.reset();
        this.offerList?.requestSubmit();
    }

    /**
     * Updates the counter for selected checkboxes within a filter item.
     * @param filterItem The filter item element.
     * @param counter The current count of selected checkboxes.
     */
    private updateCounter(filterItem: HTMLElement, counter: number): void {
        const counterElement = filterItem.querySelector('.filter__counter') as HTMLElement | null;

        if (counterElement) {
            if (counter > 0) {
                counterElement.innerHTML = `${counter}`;
                counterElement.classList.add('filter__counter--active');
            } else {
                counterElement.classList.remove('filter__counter--active');
            }
        }
    }

    /**
     * Requests an initial form submission to load offers on page load.
     */
    private requestInitialSubmit(): void {
        this.offerList?.requestSubmit();
    }
}

export const offerListManager = new OfferListManager();
