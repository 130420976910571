class searchList {
    searchModule: HTMLElement

    constructor() {
        this.searchModule = document.querySelector('.ce_smediacourse_coursesearch .search__input') as HTMLElement;

        if (this.searchModule) {
            this.loadLiveSearch();
        }
    }

    debounce<T extends (...args: any[]) => any>(func: T, timeout: number = 300): (...args: Parameters<T>) => void {
        let timer: ReturnType<typeof setTimeout>;

      return function(this: any, ...args: Parameters<T>): void {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }

    async fireLiveSearch(url: string, searchword: string) {
        try {
            const formData = new FormData();
            formData.append('tx_smediacourse_ajaxlist[searchword]', searchword);

            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.text();

            const liveSearchWrapper: HTMLDivElement = this.searchModule.parentElement.querySelector('.liveSearch');

            liveSearchWrapper.innerHTML = result;
            liveSearchWrapper.classList.add('liveSearch--active');

            const resultItems = Array.from(liveSearchWrapper.getElementsByClassName('liveSearch__item') as HTMLCollectionOf<HTMLInputElement>);

            resultItems.forEach( (item) => {
                let courseName: HTMLSpanElement = item.querySelector('.liveSearch__description');
                let saveName = courseName.innerHTML;

                courseName.innerHTML = this.highlight(searchword, courseName.innerHTML);

                item.onclick = () => {
                    (this.searchModule as HTMLInputElement).value = saveName;
                    (document.querySelector('.ce_smediacourse_coursesearch .search__form .showEq') as HTMLInputElement).value = '1';
                    (document.querySelector('.ce_smediacourse_coursesearch .search__form') as HTMLFormElement).submit();
                }
            })

            this.searchModule.addEventListener('focusout', this.debounce(() => {
                if (liveSearchWrapper.classList.contains('liveSearch--active')) liveSearchWrapper.classList.remove('liveSearch--active');
            }));

        } catch (error) {
            console.error('Error:', error);
        }
    }

    highlight(searchword: string, courseName: string) {
        const searchwords: string[] = searchword.trim().split(" ").filter(word => word.length > 0);

        for (const word in searchwords) {
            courseName = courseName.replace(
                new RegExp(`(${searchwords[word]})(?!([^<]*)?>)`, 'gi'),
                "<mark>$1</mark>"
            );
        }

        return courseName;
    }

    loadLiveSearch() {
        this.searchModule?.addEventListener('input', (event) => {
            const input = event.target as HTMLInputElement;

            const debouncedFireLiveSearch = this.debounce((url, searchword, region) => {
                if(searchword.length > 2) {
                    this.fireLiveSearch(url, searchword);
                } else {
                    const liveSearch: HTMLDivElement = this.searchModule.parentElement.querySelector('.liveSearch');
                    if (liveSearch?.classList.contains('liveSearch--active')) liveSearch?.classList.remove('liveSearch--active');
                    if (liveSearch.innerHTML !=='') {
                        liveSearch.innerHTML = '';
                    }
                }
            });

            debouncedFireLiveSearch(input.dataset.url, input.value, input.dataset.region);
        });
    }

}
export const locationList = new searchList();