class EqualHeightHelper {
    /**
     * Function to equalize the height of items to avoid different sized boxes
     * Note: To maintain functionality while calling the function insight window.resize()
     *       the height will be added to the parent element.
     *
     * @param element ce main class
     * @param itemClass ce inline item class
     * @param equalHeightClass class to compare and equalize the height
     */
    equalHeight(element: string, itemClass: string, equalHeightClass: string) {
        const allCes = Array.from(document.getElementsByClassName(element) as HTMLCollectionOf<HTMLElement>)
        allCes.forEach((ce) => {
            const Items = Array.from(ce.getElementsByClassName(itemClass) as HTMLCollectionOf<HTMLElement>);
            let height = 0;

            height = this.executeHeight(Items, equalHeightClass, false, height);
            this.executeHeight(Items, equalHeightClass, true, height)
        })
    }

    private executeHeight(Items: HTMLElement[], selector: string, exec: boolean, height: number) {
        Items.forEach((item) => {
            let box = Array.from(item.getElementsByClassName(selector) as HTMLCollectionOf<HTMLElement>);

            switch (exec) {
                case false: {
                    box.forEach((el) => {
                        height = (el.scrollHeight > height) ? el.scrollHeight : height;
                    });
                    break;
                }
                default: {
                    box.forEach((el) => {
                        el.parentElement.style.height = height + 'px';
                    });
                }
            }
        })

        return height;
    }
}

export default new EqualHeightHelper();