class LocationDetail {
    detailModule: HTMLElement

    constructor() {
        this.detailModule = document.querySelector('.location-detail') as HTMLElement;

        if (this.detailModule) {
            const rangeInput: HTMLInputElement = this.detailModule.querySelector('.filter__range');
            const rangeOutput: HTMLElement = this.detailModule.querySelector('.filter__range-output');

            rangeInput?.addEventListener('input', () => {
                rangeOutput.innerHTML = rangeInput.value + ' km';
            });

            this.renderMap();
        }
    }

    renderMap() {
        const map: HTMLElement = this.detailModule.querySelector('.location-detail__gmap');
        let latitude: number = 51.1657;
        let longitude: number = 10.4515;


        if (map) {
            let gmap: google.maps.Map;
            const parser = new DOMParser();
            const pinIconString = "<svg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 512 512' fill='#004474'><path d='M256,32c-79.529,0-144,64.471-144,144c0,112,144,304,144,304s144-192,144-304C400,96.471,335.529,32,256,32z M256,222.9 c-25.9,0-46.9-21-46.9-46.9s21-46.9,46.9-46.9s46.9,21,46.9,46.9S281.9,222.9,256,222.9z'/></svg>";
            let lastInfoWindow: any = undefined;

            if (map.getAttribute('data-latitude') !== '') latitude = +map.getAttribute('data-latitude');
            if (map.getAttribute('data-longitude') !== '') longitude = +map.getAttribute('data-longitude');

            async function initMap(latitude: number, longitude: number, map: HTMLElement): Promise<void> {
                //@ts-ignore
                const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
                //@ts-ignore
                const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

                gmap = new Map(map, {
                    center: { lat: latitude, lng: longitude },
                    zoom: 10,
                    zoomControl: true,
                    disableDoubleClickZoom: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    scrollwheel: false,
                    panControl: false,
                    streetViewControl: false,
                    draggable: true,
                    overviewMapControl: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapId: "AIzaSyCodayguStwLr3fAJCrwqNDqInotb0CXlY"
                });

                const locations: Element[] = Array.from(map.closest('.location-detail').getElementsByClassName('location-detail__item'))

                if (locations.length > 0) {
                    locations.forEach((location) => {
                        const latitude: number = +location.getAttribute('data-latitude');
                        const longitude: number = +location.getAttribute('data-longitude');
                        const icon = parser.parseFromString(pinIconString, 'image/svg+xml').documentElement;

                        const marker = new AdvancedMarkerElement({
                            map: gmap,
                            position: {
                                lat: latitude,
                                lng: longitude,
                            },
                            content: icon
                        });


                        marker.addListener("click", () => {
                            gmap.panTo(marker.position)
                            if (lastInfoWindow !== undefined) lastInfoWindow.close();
                            lastInfoWindow = new google.maps.InfoWindow({
                                content: location,
                                backgroundClassName: 'gm-style-iw',
                                maxWidth: 420,
                                minHeight: 250,
                            });

                            lastInfoWindow.open({
                                anchor: marker,
                                map: gmap,
                            });
                        });
                    })
                }

                if ((map.closest('.location-detail').querySelector('.filter__input') as HTMLInputElement)?.value !== '') {
                    const distance: number = +(map.closest('.location-detail').querySelector('.filter__range') as HTMLInputElement)?.value;

                    const cityCircle = new google.maps.Circle({
                        map: gmap,
                        center: { lat: latitude, lng: longitude },
                        fillColor: '#bbcdee',
                        strokeColor: '#0068ba',
                        clickable: false,
                        radius: distance * 1000,
                    });

                    gmap.setZoom(Math.floor(12 - (distance / 100) * 4))
                } else {
                    gmap.setZoom(8);
                }
            }

            initMap(latitude, longitude, map);
        }
    }

}
export const locationList = new LocationDetail();