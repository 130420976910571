const backToTopBtn = document.querySelector('.backToTop');
const enableBackToTopBtn = 250

if (window.location.hash) {
    if (document.querySelector(window.location.hash)) {
        let hashOffset = document.querySelector(window.location.hash).offsetTop - 120;

        window.scrollTo({
            top: hashOffset,
            behavior: "smooth"
        })
    }
}

window.addEventListener('scroll', (e) => {
    if(window.scrollY > enableBackToTopBtn) {
        backToTopBtn.classList.add('backToTop--active');
    } else {
        if (backToTopBtn.classList.contains('backToTop--active')) backToTopBtn.classList.remove('backToTop--active');
    }
});

document.querySelectorAll('a[href^="#"]').forEach((link) => {
    link.addEventListener('click', (e) => {
        if (document.querySelector(link.hash)) {
            e.preventDefault();
            e.stopPropagation();

            let hashOffset = document.querySelector(link.hash).offsetTop - 120;
            window.scrollTo({
                top: hashOffset,
                behavior: "smooth"
            })
        }
    })
})


backToTopBtn?.addEventListener('click', () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
})