import EqualHeightHelper from "../Helper/equalHeightHelper";
import Swiper from 'swiper/bundle';

if(document.querySelectorAll('.themeteaser').length > 0) {
    EqualHeightHelper.equalHeight('themeteaser', 'themeteaser__item', 'themeteaser__title');
    EqualHeightHelper.equalHeight('themeteaser', 'themeteaser__item', 'themeteaser__text');

    window.addEventListener('resize', () => {
        EqualHeightHelper.equalHeight('themeteaser', 'themeteaser__item', 'themeteaser__title');
        EqualHeightHelper.equalHeight('themeteaser', 'themeteaser__item', 'themeteaser__text');
    });
}

const swiper = new Swiper(".themeteaser--swiper", {
    slidesPerView: 1,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".teaser__pagination",
        clickable: true
    },
    breakpoints: {
        992: {
          slidesPerView: 3,
          spaceBetween: 32,
        }
      },
});