class OfferDetail {
    detail: HTMLElement | null;

    constructor() {
        this.detail = document.querySelector('.offer-detail');
        this.initialize();
    }

    private initialize(): void {
        if (this.detail) {
            this.addEventListeners();
            this.initializeTabs();
        }
    }

    private addEventListeners(): void {
        const backToList = this.detail?.querySelector('.offer-detail__header-link--backToList') as HTMLLinkElement | null;

        if (backToList) {
            backToList.addEventListener('click', (event: Event) => this.handleBackButton(event));
        }
    }

    private handleBackButton(event: Event): void {
        event.preventDefault();
        history.back();
    }

    private initializeTabs(): void {
        const allTabsTitle = Array.from(this.detail?.getElementsByClassName('tabs__title') as HTMLCollectionOf<HTMLElement>);
        const allTabsContent = Array.from(this.detail?.getElementsByClassName('tabs__content') as HTMLCollectionOf<HTMLElement>);

        if (allTabsTitle.length > 0 && allTabsContent.length > 0) {
            allTabsTitle[0].classList.add('tabs__title--active');
            allTabsContent[0].classList.add('tabs__content--active');

            allTabsTitle.forEach((tab, index) => {
                tab.addEventListener('click', () => this.handleTabClick(tab, index, allTabsTitle, allTabsContent));
            });
        }
    }

    private handleTabClick(
        clickedTab: HTMLElement,
        clickedIndex: number,
        allTabsTitle: HTMLElement[],
        allTabsContent: HTMLElement[]
    ): void {
        if (!clickedTab.classList.contains('tabs__title--active')) {
            allTabsTitle.forEach((tab) => tab.classList.remove('tabs__title--active'));
            allTabsContent.forEach((content) => content.classList.remove('tabs__content--active'));

            clickedTab.classList.add('tabs__title--active');
            allTabsContent[clickedIndex].classList.add('tabs__content--active');
        }
    }
}

export const offerDetail = new OfferDetail();
