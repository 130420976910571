if (document.querySelectorAll('.accordion').length > 0) {
    let accordions = document.querySelectorAll('.accordion');

    accordions.forEach(accordion => {
        accordion?.querySelectorAll('.accordion__item').forEach(item => {
            item.addEventListener('click', () => {
                if(item.classList.contains('accordion__item--active')) {
                    item.classList.remove('accordion__item--active');
                } else {
                    accordion.querySelectorAll('.accordion__item').forEach(sibling => {
                        sibling.classList.remove('accordion__item--active')
                    });

                    item.classList.add('accordion__item--active');
                }
            })
        });
    })
}


