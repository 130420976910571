import Swiper from 'swiper/bundle';

if (document.querySelectorAll('.gallery').length > 0) {
    document.querySelectorAll('.gallery').forEach(gallery => {
        if (gallery.querySelector('.swiper-container')) {

            const mainSwiper = new Swiper(gallery.querySelector('.swiper-container'), { // comeback here
                loop: true,
                autoplay: true,
                allowTouchMove: false,
            });
        }

        let swiper: Swiper = new Swiper('');

        let button = gallery.querySelector('.gallery__lightbox-button');

        button?.addEventListener('click', () => {
            swiper = new Swiper(gallery.querySelector('.gallery__lightbox'), { // comebach here
                initialSlide: 0,
                autoplay: false,
                pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                },
                navigation: {
                    nextEl: ".gallery__button--next",
                    prevEl: ".gallery__button--prev",
                },
            });

            gallery.querySelector('.gallery__lightbox-wrapper')?.classList.add('gallery__lightbox-wrapper--active');
            document.querySelector('body')?.classList.add('overflow-hidden');
        })

        gallery.querySelectorAll('.swiper-container .gallery__item')?.forEach(item => {
            item.addEventListener('click', () => {
                let slide: number =  item.getAttribute('data-slide');

                swiper = new Swiper(gallery.querySelector('.gallery__lightbox'), { // comeback here
                    initialSlide: slide,
                    autoplay: false,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        type: "fraction",
                    },
                    navigation: {
                        nextEl: ".gallery__button--next",
                        prevEl: ".gallery__button--prev",
                    },
                });

                gallery.querySelector('.gallery__lightbox-wrapper')?.classList.add('gallery__lightbox-wrapper--active');
                document.querySelector('body')?.classList.add('overflow-hidden');
            })
        })

        let closeButton = gallery.querySelector('.gallery__close-lightbox');

        closeButton?.addEventListener('click', () => {
            gallery.querySelector('.gallery__lightbox-wrapper')?.classList.remove('gallery__lightbox-wrapper--active');
            document.querySelector('body')?.classList.remove('overflow-hidden');

            swiper.destroy(true, true);
        })
    });
}