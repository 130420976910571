class LocationList {
    listModule: HTMLElement

    constructor() {
        this.listModule = document.querySelector('.location-list') as HTMLElement;

        if (this.listModule) {
            const mapRegions: SVGPathElement[] = Array.from(this.listModule.querySelector('.location-list__map')?.getElementsByTagName('path'));
            const regions: Element[] = Array.from(this.listModule.querySelector('.location-list__regions')?.getElementsByClassName('region__item'));

            this.enableHover(mapRegions, regions, true);
            this.enableHover(regions, mapRegions, false);
        }
    }

    enableHover(hoverableList: Element[], listToEnableHover: Element[], map: boolean) {
        hoverableList.forEach((hoverableItem, index) => {
            const correspondingItem = listToEnableHover[index] as HTMLElement;

            if (correspondingItem) {
                hoverableItem.addEventListener('mouseover', (event) => {
                    correspondingItem.classList.add('location-list__hovered');

                    if (map === true) {
                        this.updateMousePosition(event as MouseEvent, correspondingItem, false);
                    }
                });

                hoverableItem.addEventListener('mouseout', (event) => {
                    correspondingItem.classList.remove('location-list__hovered');

                    if (map === true) {
                        this.updateMousePosition(event as MouseEvent, correspondingItem, true);
                    }
                });

                hoverableItem.addEventListener('mousemove', (event) => {
                    if (map === true) {
                        this.updateMousePosition(event as MouseEvent, correspondingItem, false);
                    }
                });
            }

            if (map === true) {
                hoverableItem.addEventListener('click', () => {
                    correspondingItem.click();
                })
            }
        });
    }

    updateMousePosition(event: MouseEvent, itemToDuplicate: HTMLElement, removeClass: boolean) {
        const popup: HTMLDivElement = this.listModule.querySelector('.location-list__popup');

        if (popup) {
            if (removeClass == false) {
                if (popup.classList.contains('d-none')) popup.classList.remove('d-none');
                popup.innerHTML = '';
                popup.appendChild(itemToDuplicate.cloneNode(true));
                if (event.pageX < (itemToDuplicate.offsetWidth + 40)) {
                    popup.setAttribute('style', 'left: ' + (event.pageX + 40) + 'px; top: ' + (event.pageY - itemToDuplicate.offsetHeight) + 'px; width: ' + itemToDuplicate.offsetWidth + 'px');
                } else {
                    popup.setAttribute('style', 'left: ' + (event.pageX - itemToDuplicate.offsetWidth - 40) + 'px; top: ' + (event.pageY - itemToDuplicate.offsetHeight) + 'px; width: ' + itemToDuplicate.offsetWidth + 'px');
                }
            } else {
                popup.classList.add('d-none');
            }
        }
    }
}
export const locationList = new LocationList();