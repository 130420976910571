const detail: HTMLElement = document.querySelector('.course-detail');

if (detail) {
    initializeJsBack(detail);
    initializeMap(detail);
    initializeTabs(detail);
}

function initializeJsBack(detail: HTMLElement) {
    const backToList = detail.querySelector('.course-detail__header-link--backToList') as HTMLLinkElement;

    backToList.addEventListener('click', () => {
        history.back();
    })
}

function initializeMap(detail: HTMLElement) {
    const map = detail.querySelector('.course-detail__map') as HTMLElement;
    const latitude: number = +map.getAttribute('data-latitude');
    const longitude: number = +map.getAttribute('data-longitude');

    let gmap;

    async function initMap(latitude: number, longitude: number, map: HTMLElement): Promise<void> {

        //@ts-ignore
        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

        gmap = new Map(map, {
            center: { lat: latitude, lng: longitude },
            zoom: 15,
            zoomControl: false,
            disableDoubleClickZoom: true,
            mapTypeControl: false,
            scaleControl: false,
            scrollwheel: false,
            panControl: false,
            streetViewControl: false,
            draggable: false,
            overviewMapControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapId: "AIzaSyCodayguStwLr3fAJCrwqNDqInotb0CXlY"
        });

        const marker = new AdvancedMarkerElement({
            map: gmap,
            position: {
              lat: latitude,
              lng: longitude,
            },
        });
    }

    initMap(latitude, longitude, map);
}

function initializeTabs(detail: HTMLElement) {
    const allTabsTitle = Array.from(detail.getElementsByClassName('tabs__title') as HTMLCollectionOf<HTMLElement>);
    const allTabsContent = Array.from(detail.getElementsByClassName('tabs__content') as HTMLCollectionOf<HTMLElement>);

    if(allTabsTitle.length > 0) {
        allTabsTitle.forEach((item, index) => {
            if (index === 0) {
                item.classList.add('tabs__title--active');
            }

            item.addEventListener('click', () => {
                if (!item.classList.contains('tabs__title--active')) {
                    allTabsTitle.forEach((item, index) => {
                        if(item.classList.contains('tabs__title--active')) {
                            item.classList.remove('tabs__title--active');
                            allTabsContent[index].classList.remove('tabs__content--active')
                        }
                    });

                    item.classList.add('tabs__title--active');
                    allTabsContent[index].classList.add('tabs__content--active')
                }
            })
        })
    }

    if(allTabsContent.length > 0) {
        allTabsContent.forEach((item, index) => {
            if (index === 0) {
                item.classList.add('tabs__content--active');
            }
        })
    }
}
