// import Swiper JS
import Swiper from 'swiper/bundle';

if (document.querySelector('.slider')) {
    const swiper = new Swiper(".slider", {
        loop: true,
        autoplay: {
            delay: 4000
        },
        pagination: {
            el: ".slider__pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".slider__button--next",
            prevEl: ".slider__button--prev",
        },
        on: {
            realIndexChange: () => {
                changeNavigationPositionMobile(swiper.realIndex);
            },
        }
    });

    changeNavigationPositionMobile();

    function changeNavigationPositionMobile(realIndex = 0) {
        if (window.matchMedia("(max-width: 991.98px)").matches) {
            let itemHeight = document.querySelector('.slider__item[data-swiper-slide-index="' + realIndex + '"]')?.getBoundingClientRect().height ?? 0;
            let contentHeight = document.querySelector('.slider__item[data-swiper-slide-index="' + realIndex + '"] .slider__content-wrapper')?.getBoundingClientRect().height ?? 0;
            document.querySelector('.slider__navigation')?.setAttribute('style', 'top: ' + (itemHeight - contentHeight - 16) + 'px');
        }
    }
}


